import React from "react"
import striptags from "striptags"
import { Link } from "gatsby"

import BreadcrumbsBlog from "../../containers/BreadcrumbsBlog"
import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import Text from "../../components/Text"
import BannerWrapper, {
  BannerContent,
  ButtonGroup,
  Overlay,
} from "./banner.style"

const Banner = ({
  title,
  subtitle,
  image,
  breadcrumbs,
  name,
  mainButtonText,
  mainButtonLink,
  secButtonText,
  secButtonLink,
}) => {
  return (
    <BannerWrapper image={image}>
      <Overlay>
        <Container width="1220px">
          <BannerContent>
            <Heading as="h1" content={striptags(title)} />
            {subtitle && <Text content={subtitle} />}
            {breadcrumbs && <BreadcrumbsBlog name={name} />}
            {mainButtonText && secButtonText ? (
              <ButtonGroup>
                <Link to={`${mainButtonLink}/`}>
                  <Button className="primary" title={mainButtonText} />
                </Link>
                <Link to={`${secButtonLink}/`}>
                  <Button className="sec" title={secButtonText} />
                </Link>
              </ButtonGroup>
            ) : null}
          </BannerContent>
        </Container>
      </Overlay>
    </BannerWrapper>
  )
}

export default Banner
