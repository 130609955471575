import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const DropwdownWrapper = styled.section`
  border-top: 2px solid ${themeGet("colors.mainBlue")};
  border-bottom: 2px solid ${themeGet("colors.mainBlue")};
  margin-bottom: 80px;
  padding: 32px 0;
  @media only screen and (max-width: 1440px) {
    margin-bottom: 50px;
  }
  @media only screen and (max-width: 990px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 800px) {
    margin-bottom: 25px;
    border-top: none;
    border-bottom: none;
    padding: 0 0 20px 0;
  }
`

export const DropdownContent = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }

  .dropdown {
    cursor: pointer;
    text-transform: capitalize;
    position: relative;
    width: 33.333333%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-right: 1px solid ${themeGet("colors.mainBlue")};
    @media only screen and (max-width: 800px) {
      width: 100%;
      border-right: none;
      padding: 20px 0;
      border-bottom: 1px solid ${themeGet("colors.mainBlue")};
    }
    &:last-child {
      border-right: none !important;
    }
  }

  .dropdown-toggler {
    cursor: pointer;
    margin-right: 24px;
    font-size: 16px;
    color: ${themeGet("colors.mainBlue")};
    font-weight: 700;
    background-color: transparent;
    border: none;
    @media only screen and (max-width: 800px) {
      font-size: 16px;
    }
  }

  ul.dropdown-menu {
    z-index: 1;
    width: 100%;
    background-color: ${themeGet("colors.white")};
    position: absolute;
    top: 53px;
    border: 1px solid ${themeGet("colors.mainBlue")};
    @media only screen and (max-width: 800px) {
      top: 53px;
    }
    .dropdown-menu-item {
      border-bottom: 1px solid ${themeGet("colors.mainBlue")} !important;
      cursor: pointer;
      padding: 32px 0;
      color: ${themeGet("colors.mainBlue")};
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
      text-align: center;
      transition: all 0.1s ease-in;
      @media only screen and (max-width: 800px) {
        font-size: 16px;
      }
      &:last-child {
        border-bottom: transparent;
      }
      &:hover {
        color: ${themeGet("colors.white")};
        background-color: ${themeGet("colors.mainBlue")};
      }
    }
  }
  ul.dropdown-menu-alt {
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    z-index: 1;
    width: 100%;
    background-color: ${themeGet("colors.white")};
    position: absolute;
    top: 53px;
    border: 1px solid ${themeGet("colors.mainBlue")};
    @media only screen and (max-width: 800px) {
      top: 53px;
    }
    .dropdown-menu-item {
      cursor: pointer;
      padding: 8px;
      margin-bottom: 16px;
      color: ${themeGet("colors.white")};
      margin-right: 16px;
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      transition: all 0.1s ease-in;
      border: 1px solid ${themeGet("colors.mainBlue")};
      background-color: ${themeGet("colors.mainBlue")};
      border-radius: 20px;

      @media only screen and (max-width: 800px) {
        font-size: 16px;
      }
      &:hover {
        color: ${themeGet("colors.mainBlue")};
        background-color: ${themeGet("colors.white")};
      }
    }
  }
`

export default DropwdownWrapper
