import SectionWrapper, {
  AllArticles,
  Author,
  Content,
  Inner,
  Photo,
} from "./author.style"

import Arrow from "../../common/assets/image/alpacked/services/arrow.svg"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import { Icon } from "react-icons-kit"
import Img from "gatsby-image"
import { Link } from "gatsby"
import React from "react"
import Text from "../../components/Text"
import { linkedin } from "react-icons-kit/fa/linkedin"
import striptags from "striptags"

const SingleAuthor = ({
  name,
  occupation,
  linkedin_profile,
  details,
  image,
  articlesNumber,
}) => {
  return (
    <SectionWrapper>
      <Container width="1220px" className="container">
        <Inner>
          <Photo>
            <Img
              fadeIn={false}
              loading="lazy"
              fluid={image.localFile?.childImageSharp.fluid}
              className="image"
              alt="author Image"
            />
            <Link to="/contacts/" className="questionLink">
              <Text content="ASK A QUESTION" className="askQuestion" />
              <img
                loading="lazy"
                src={Arrow}
                alt="arrowLight"
                className="arrow"
              />
            </Link>
          </Photo>
          <Content>
            <div>
              <Author>
                <Heading
                  as={name.html.substring(1, 3)}
                  content={striptags(name.html)}
                />
                <a
                  aria-label="Linkedin"
                  href={linkedin_profile.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon icon={linkedin} />
                </a>
              </Author>
              <Text content={occupation.text} className="occupation" />
              <hr className="seperator" />
              <div
                className="details"
                dangerouslySetInnerHTML={{ __html: details.html }}
              />
            </div>
            <AllArticles>
              <Text
                content={`TOTAL: ${articlesNumber} ARTICLES`}
                className="allArticles"
              />
            </AllArticles>
          </Content>
        </Inner>
      </Container>
    </SectionWrapper>
  )
}

export default SingleAuthor
