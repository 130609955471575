import React from "react"
import striptags from "striptags"
import { Link } from "gatsby"

import Arrow from "../../common/assets/image/alpacked/authors/arrow.svg"
import Text from "../../components/Text"
import SectionWrapper, { Content } from "./breadcrumbsBlog.style"

const BreadcrumbsBlog = ({ name }) => {
  const splitName = name => {
    const nameparts = striptags(name.html).split(" ")
    const initials =
      nameparts[0] + " " + nameparts[1].charAt(0).toUpperCase() + "."
    return initials
  }

  return (
    <SectionWrapper>
      <Content>
        {name ? (
          <>
            <Link to="/">
              <Text content="HOME PAGE" className="links" />
            </Link>
            <img loading="lazy" src={Arrow} alt="breadcrumbs-arrow" />
            <Link to="/blog/">
              <Text content="BLOG" className="links" />
            </Link>
            <img loading="lazy" src={Arrow} alt="breadcrumbs-arrow" />
            <Link to="/blog/authors/">
              <Text content="AUTHORS" className="links" />
            </Link>
            <img loading="lazy" src={Arrow} alt="breadcrumbs-arrow" />
            <Text content={splitName(name)} className="current-page" />
          </>
        ) : (
          <>
            <Link to="/">
              <Text content="HOME PAGE" className="links" />
            </Link>
            <img loading="lazy" src={Arrow} alt="breadcrumbs-arrow" />
            <Link to="/blog/">
              <Text content="BLOG" className="links" />
            </Link>
            <img loading="lazy" src={Arrow} alt="breadcrumbs-arrow" />
            <Text content="AUTHORS" className="current-page" />
          </>
        )}
      </Content>
    </SectionWrapper>
  )
}

export default BreadcrumbsBlog
