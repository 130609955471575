import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Dropdown from "../../components/Dropdown"
import DropdownWrapper, { DropdownContent } from "./dropdownBlog.style"

const DropdownBlog = () => {
  const dropdown = useStaticQuery(graphql`
    query DropdownData {
      allPrismicBlogPost {
        distinct(field: tags)
      }
      allPrismicAuthor {
        distinct(field: data___name___text)
      }
    }
  `)

  const tags = dropdown.allPrismicBlogPost.distinct
  const authors = dropdown.allPrismicAuthor.distinct

  return (
    <DropdownWrapper>
      <DropdownContent>
        <Dropdown
          className="dropdown"
          placeholder="ALL CATEGORIES"
          options={[
            "all categories",
            "beginner",
            "expert",
            "cases",
            "business",
          ]}
          categories
        />
        <Dropdown className="dropdown" placeholder="TAGS" options={tags} tags />
        <Dropdown
          className="dropdown"
          placeholder="AUTHORS"
          options={authors}
          authors
        />
      </DropdownContent>
    </DropdownWrapper>
  )
}

export default DropdownBlog
