import Img from "gatsby-image"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const BlogPost = ({
  className,
  thumbUrl,
  title,
  excerpt,
  link,
  category,
  date,
  author,
  tags,
  slug,
}) => {
  // Add all classes to an array
  const addAllClasses = ["blog_post"]

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  function truncate(str, no_words) {
    return `${str.split(" ").splice(0, no_words).join(" ")}...`
  }

  return (
    <div className={addAllClasses.join(" ")}>
      <div>
        <Link to={`/blog/${slug}/`}>
          <div className="overlay">
            <div className="thumbnail">
              <Img
                fluid={thumbUrl}
                alt={`${title} thumbnail`}
                className="image"
                fadeIn={true}
                loading="lazy"
              />
              <div className="post-details">
                <div>
                  {tags.map((tag, index) => {
                    return (
                      <p className="tag" key={index}>
                        {tag}
                      </p>
                    )
                  })}
                </div>
                <div className="author">
                  <Img
                    fadeIn={true}
                    loading="lazy"
                    fluid={
                      author.document.data.image.localFile?.childImageSharp
                        .fluid
                    }
                    alt={author.document.data.name.text}
                    className="author-avatar"
                  />
                  <div>
                    <p className="author-name">
                      {author.document.data.name.text}
                    </p>
                    <p className="author-occupation">
                      {author.document.data.occupation.text}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="details">
              <div className="category-details">
                <p className="category">{category}</p>

                <hr className="seperator" />
              </div>
              <p className="date">{date}</p>
            </div>
            <h3 className="title">{title}</h3>
            <p className="excerpt">{truncate(excerpt, 25)}</p>
          </div>
        </Link>
      </div>
      {link && <div className="learn_more">{link}</div>}
    </div>
  )
}

BlogPost.propTypes = {
  className: PropTypes.string,
  thumbUrl: PropTypes.object,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  link: PropTypes.element,
  category: PropTypes.string,
  date: PropTypes.string,
  author: PropTypes.object,
  tags: PropTypes.array,
  slug: PropTypes.string,
}

export default BlogPost
