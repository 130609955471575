import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  position: relative;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  text-transform: uppercase;
  align-items: center;

  .links {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: ${themeGet("colors.white")};
    margin: 18px 0 0;
    margin-right: 16px;
    @media only screen and (max-width: 990px) {
      font-size: 16px;
      margin-right: 16px;
      margin-top: 0px;
    }
  }

  img {
    margin-top: 18px;
    margin-right: 16px;
    width: 10px;
    @media only screen and (max-width: 990px) {
      margin-right: 16px;
      margin-top: 0px;
    }
  }

  .current-page {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: ${themeGet("colors.mainBlue")};
    margin: 16px 0 0;
    @media only screen and (max-width: 990px) {
      font-size: 16px;
      margin: 0px;
    }
  }

  @media only screen and (max-width: 991px) {
    margin-top: 40px;
    width: 100%;
  }
`

export default SectionWrapper
