import React from "react"
import { graphql } from "gatsby"

import Author from "../containers/Author"
import AuthorsBanner from "../common/assets/image/alpacked/authors/authors.jpg"
import Banner from "../containers/Banner"
import BlogPostsList from "../containers/BlogPostsList"
import Consultation from "../containers/Consultation"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/alpacked.style"

const AuthorTemplate = ({ data, location }) => {
  const {
    name,
    occupation,
    linkedin_profile,
    details,
    image,
    seo_title,
    seo_short_description,
    seo_description,
    image_link_preview,
  } = data.prismicAuthor.data

  const { edges } = data.allPrismicBlogPost

  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout pathname={location.pathname}>
        <ContentWrapper>
          <Banner
            title={name.html}
            breadcrumbs
            image={AuthorsBanner}
            name={name}
          />
          <Author
            name={name}
            occupation={occupation}
            linkedin_profile={linkedin_profile}
            details={details}
            image={image}
            articlesNumber={edges.length}
          />
          <BlogPostsList content={edges} name={name.text} />
          <Consultation pathname={location.pathname} />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default AuthorTemplate

export const authorQuery = graphql`
  query SingleAuthor($slug: String!) {
    prismicAuthor(uid: { eq: $slug }) {
      data {
        seo_title {
          text
        }
        seo_short_description {
          text
        }
        seo_description {
          text
        }
        image_link_preview {
          fluid {
            src
          }
        }
        name {
          html
        }
        occupation {
          text
        }
        linkedin_profile {
          url
        }
        details {
          html
        }
        image {
          localFile {
            childImageSharp {
              fluid(quality: 72) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    allPrismicBlogPost(
      filter: { data: { author: { uid: { eq: $slug } } } }
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          uid
          id
          tags
          first_publication_date(formatString: "MMM DD, YYYY")
          data {
            category
            featured_article
            intro_text
            title {
              text
            }
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1400, quality: 72) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            author {
              document {
                ... on PrismicAuthor {
                  id
                  data {
                    name {
                      html
                    }
                    occupation {
                      text
                    }
                    image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 1400, quality: 72) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
