import React, { useContext, useEffect, useState } from "react"
import SectionWrapper, {
  ContentWrapper,
  HeaderWrapper,
} from "./blogPostsList.style"

import Arrow from "../../common/assets/image/alpacked/authors/arrow_blog.svg"
import BlogPost from "../../components/BlogPost"
import Button from "../../components/Button"
import Container from "../../components/UI/Container"
import DropdownBlog from "../../containers/DropdownBlog"
import InfiniteScroll from "react-infinite-scroll-component"
import { Link } from "gatsby"
import TitleSection from "../TitleSection"
import { VisibilityContext } from "../../contexts/VisibilityContext"

const BlogPostsList = ({ content, name, home }) => {
  const context = useContext(VisibilityContext)
  const [fetchPosts, setFetchPosts] = useState(content.slice(0, 6))
  const [step, setStep] = useState(6)

  useEffect(() => {
    if (fetchPosts.length !== content.length) {
      context.setVisible(false)
    }
  })

  const fetchMoreData = () => {
    setFetchPosts(fetchPosts.concat(content.slice(step, step + 6)))
    setStep(step + 6)
    if (fetchPosts.length === content.length) {
      context.setVisible(true)
    }
  }

  const splitName = name => {
    const nameparts = name.split(" ")
    const initials = nameparts[0]
    return initials
  }

  return (
    <SectionWrapper>
      <Container width="1220px">
        {name ? (
          <TitleSection
            titleA="List of"
            titleB={`${splitName(name)}'s articles`}
          />
        ) : home ? (
          <HeaderWrapper>
            <TitleSection
              titleA="Learn more about our"
              titleB="success stories"
              subtitle="<p>We support our clients across the following core pillars but not limited to.</p>"
            />
            <Link to="/blog/">
              <Button className="sec" title="GO TO BLOG" />
            </Link>
          </HeaderWrapper>
        ) : (
          <TitleSection titleA="All" titleB="articles" />
        )}
        <ContentWrapper>
          {!home && <DropdownBlog />}
          <InfiniteScroll
            dataLength={fetchPosts.length}
            next={fetchMoreData}
            hasMore={true}
          >
            <div className="infinite">
              {fetchPosts.map(blogPost => {
                const {
                  author,
                  category,
                  image,
                  title,
                  intro_text,
                  publication_date,
                } = blogPost.node.data
                return (
                  <BlogPost
                    slug={blogPost.node.uid}
                    date={
                      publication_date || blogPost.node.first_publication_date
                    }
                    tags={blogPost.node.tags}
                    key={blogPost.node.id}
                    category={category}
                    author={author}
                    thumbUrl={image.localFile?.childImageSharp?.fluid}
                    title={title.text}
                    excerpt={intro_text}
                    link={
                      <Link
                        to={`/blog/${blogPost.node.uid}/`}
                        className="excerptLink"
                      >
                        SHOW MORE
                        <img
                          loading="lazy"
                          src={Arrow}
                          alt="arrow"
                          className="arrow"
                        />
                      </Link>
                    }
                  />
                )
              })}
            </div>
          </InfiniteScroll>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  )
}

export default BlogPostsList
